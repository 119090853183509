<template>
    <nav class="select-none overflow-y-auto">
        <div class="space-y-1">
            <NuxtLink
                v-for="item in mainItems"
                :key="item.name"
                :to="item.to"
                :class="[
                    linkActiveClassList(item.isActive),
                    'group flex items-center rounded-md p-2 text-sm font-medium',
                ]"
                @click="appStore.mobileSidebarOpen = false"
            >
                <component
                    :is="item.icon"
                    :class="[
                        linkActiveIconClassList(item.isActive),
                        'mr-3 h-6 w-6 flex-shrink-0',
                    ]"
                    aria-hidden="true"
                />
                {{ item.name }}
            </NuxtLink>
            <template v-if="isOwner">
                <hr class="!my-3 border-gray-500/50" />
                <NuxtLink
                    v-for="item in bottomItems"
                    :key="item.name"
                    :to="item.to"
                    :class="[
                        linkActiveClassList(item.isActive),
                        'group flex items-center rounded-md p-2 text-sm font-medium',
                    ]"
                    @click="appStore.mobileSidebarOpen = false"
                >
                    <component
                        :is="item.icon"
                        :class="[
                            linkActiveIconClassList(item.isActive),
                            'mr-3 h-6 w-6 flex-shrink-0',
                        ]"
                        aria-hidden="true"
                    />
                    {{ item.name }}
                </NuxtLink>
            </template>
        </div>
    </nav>
</template>

<script setup lang="ts">
import {
    HomeIcon,
    UsersIcon,
    ReceiptPercentIcon,
    ShoppingBagIcon,
    CircleStackIcon,
    Cog8ToothIcon,
    FolderOpenIcon,
} from '@heroicons/vue/24/outline'

const router = useRouter()
const { t } = useI18n()
const appStore = useAppStore()
const { isOwner } = useRole()

const isActive = (to?: string) => {
    if (!to) return false

    return to === companyPath('/')
        ? router.currentRoute.value.path === to
        : router.currentRoute.value.path.startsWith(to)
}

const mainItems = computed(() => [
    {
        name: t('dashboard'),
        to: companyPath('/'),
        icon: markRaw(HomeIcon),
        isActive: isActive(companyPath('/')),
    },
    {
        name: t('contact', 2),
        to: companyPath('/contacts'),
        icon: markRaw(UsersIcon),
        isActive: isActive(companyPath('/contacts')),
    },
    {
        name: t('revenue', 2),
        to: companyPath('/revenues'),
        icon: markRaw(ReceiptPercentIcon),
        isActive: isActive(companyPath('/revenues')),
    },
    {
        name: t('expense', 2),
        to: companyPath('/expenses'),
        icon: markRaw(ShoppingBagIcon),
        isActive: isActive(companyPath('/expenses')),
    },
    {
        name: t('item', 2),
        to: companyPath('/items'),
        icon: markRaw(CircleStackIcon),
        isActive: isActive(companyPath('/items')),
    },
    {
        name: t('page.files.title'),
        to: companyPath('/files'),
        icon: markRaw(FolderOpenIcon),
        isActive: isActive(companyPath('/files')),
    },
])
const bottomItems = computed(() => [
    {
        name: t('settings'),
        to: companyPath('/settings/general'),
        icon: markRaw(Cog8ToothIcon),
        isActive: isActive(companyPath('/settings')),
    },
])

function linkActiveClassList(isActive?: boolean) {
    return isActive
        ? 'bg-gray-900 text-white'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
}

function linkActiveIconClassList(isActive?: boolean) {
    return isActive
        ? 'text-gray-300'
        : 'text-gray-400 group-hover:text-gray-300'
}
</script>
